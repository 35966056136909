.site-header

  .container
    position: relative

  &__name
    margin: 30px 0 20px 0
    text-transform: uppercase
    font-size: 22px
    font-weight: 700

    @media (min-width: $breakpoint-menu)
      margin: 80px 0 10px 0

    a
      text-decoration: none
      color: $black

  &__toggle
    display: block
    position: absolute
    top: -8px
    right: 0
    width: 40px
    height: 40px
    @media (min-width: $breakpoint-menu)
      display: none

    .burger
      display: block
      position: relative
      background: $black
      width: 20px
      height: 2px
      top: 19px
      left: 10px
      transition: all .2s

      &:before,
      &::after
        content: ""
        display: block
        position: absolute
        top: -6px
        left: 0
        width: 20px
        height: 2px
        background: $black
        overflow: visible
        transition: all .2s

      &::after
        top: 6px

      .menu-is-open &
        background: transparent
        &:before,
        &:after
          top: 0
        &:before
          transform: rotate(45deg)
        &::after
          transform: rotate(-45deg)


    span
      display: none

.nav-main
  display: none
  margin-bottom: 30px
  font-size: 13px
  line-height: 1.3em

  .menu-is-open &
    display: block

  @media (min-width: $breakpoint-menu)
    display: block
    margin-bottom: 55px
  +desktop
    margin-bottom: 24px

  a

    +link-two-colors(#000, #000)
    text-decoration: none
    &:hover
      border-bottom: 1px solid $black
    @media (min-width: $breakpoint-menu)
      display: inline
      padding: 0

  ul
    position: relative
    display: inline
    margin: 0
    padding: 0
    li
      text-transform: uppercase
      list-style: none
      margin: 6px 0

      @media (min-width: $breakpoint-menu)
        display: inline-block
        margin: 0 15px 0 0
        text-align: left

      ul
        display: none
        margin-bottom: 15px

      li
        text-transform: none
        margin-left: 10px

        @media (min-width: $breakpoint-menu)
          display: inline-block
          margin-left: 0

    li.current-menu-item,
    li.current-menu-ancestor
      > a
        border-bottom: 1px solid $black
      ul
        display: block


        @media (min-width: $breakpoint-menu)
          position: absolute
          width: 700px
          left: 0
          top: 25px

        +desktop
          top: 2px
          left: 200px

    ul
      @media (min-width: $breakpoint-menu)
        display: none

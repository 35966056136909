.gform_fields
  +clearfix
  +no-bullets
  padding: 0
  margin: 0

.gfield
  margin-bottom: 10px !important
  +desktop
    +span(6)

.gfield__first
  clear: both

.gfield__full
  margin-bottom: 0 !important
  +desktop
    +span(12)

input[type="text"],
input[type="email"],
input[type="submit"],
textarea
  width: 100%
  border: 3px solid $grey
  padding: 25px
  background: $white

  .gfield_error &
    border-color: $error

  textarea
    height: 218px !important

.gfield_select
  width: 100%
  border: 3px solid $grey
  padding: 25px !important
  appearance: none
  background: image-url('ui@2x/arrow-down.png') no-repeat
  background-position: top 30px right 20px
  background-size: 18px 11px

.gform_footer
  margin: 0 !important
  padding: 5px 0 0 0 !important
  .gform_button
    width: 218px
    margin-top: 10px

.gfield_label
  .js &
    display: none !important


.validation_message
  font-size: 12px
  color: $error

.validation_error
  margin-bottom: 23px
  color: $error


+placeholder
  color: $black

// Set placeholder color when using js for browser wihtout placeholder support.
.placeholdersjs
  color: $black


// Hide honeypot field.
#field_1_6
  display: none

.thumbnail
  margin-bottom: 10px
  background: #ddd

  +mobile-large
    +gallery(3 of 6)

    .lt-ie9 &
      margin-right: 10px
  +tablet-small
    +gallery(3 of 9)
  +tablet-large
    +gallery(3 of 12)
  +desktop
    +gallery(3 of 13)

  &__link
    padding-top: 72%
    position: relative
    display: block

  &__image,
  &__image-bw
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    display: block
    img
      width: 100%
      height: auto
  &__image-bw
    opacity: 1
    transition: opacity 0.2s


  &__informations
    position: absolute
    left: 0
    right: 0
    bottom: 0
    background: rgba(255, 255, 255, 0.3)
    font-size: 14px
    text-transform: uppercase
    line-height: 1.3em
    .no-touch &
      opacity: 0
      transition: opacity 0.2s

    &--collaborateur
      opacity: 1 !important

  &__informations-inner
    position: relative
    padding: 10px

    p
      margin: 0
      padding: 0

  &__content
    margin-top: 1em
    text-transform: none

  a
    color: #000
    outline: none
  a:hover
    .thumbnail__informations
      .no-touch &
        opacity: 1
        transition: opacity 0.2s
    .thumbnail__image-bw
      .no-touch &
        opacity: 0
        transition: opacity 0.2s

  &__title
    font-size: 15px
    margin: 0
    padding: 0
    line-height: 1.3em

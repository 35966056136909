@mixin font-smoothing($value: true)
  @if $value == true
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    font-smooth: always
  @else
    -webkit-font-smoothing: subpixel-antialiased
    -moz-osx-font-smoothing: auto
    font-smooth: auto

@mixin link-colors($normal, $hover: false, $active: false, $visited: false, $focus: false)
  color: $normal
  @if $visited
    &:visited
      color: $visited

  @if $focus
    &:focus
      color: $focus

  @if $hover
    &:hover
      color: $hover

  @if $active
    &:active
      color: $active

@mixin link-two-colors($normal, $hover)
  //+link-colors($link-color, $link-hover-color, $link-active-color, $link-visited-color, $link-focus-color)
  +link-colors($normal, $hover, $normal, $normal, $hover)

@mixin animate($layout)
  @include breakpoint($layout)
    .js.csstransitions &
      @content

@function str-replace($string, $search, $replace: '')
  $index: str-index($string, $search)
  $slice: $string

  @while $index
    $slice: if($index == 1, '', str-slice($slice, 0, $index - 1)) + $replace + str-slice($slice, $index + max(str-length($replace), 1))
    $index: str-index($slice, $search)

  @return $slice

@mixin reset-box-model()
  display: block
  margin: 0
  padding: 0

@mixin no-bullet()
  list-style-image: none
  list-style-type: none
  margin-left: 0

@mixin no-bullets()
  list-style: none
  li
    +no-bullet

@mixin clearfix
  overflow: hidden

@mixin pie-clearfix
  *zoom: 1
  &:before, &:after
    content: ""
    display: table
  &:after
    clear: both

@mixin susy-box-sizing($model: content-box)
  // No need to use the box-sizing mixin, will be handled by autoprefixer.
  // This avoid warning with bourbon.
  box-sizing: $model

@mixin inline-block
  // No need to use the box-sizing mixin, will be handled by autoprefixer.
  // This avoid warning with bourbon.
  display: inline-block

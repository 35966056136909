.project

  &__title-mobile
    font-size: 15px
    font-weight: bold
    line-height: 1.2em
    text-transform: uppercase
    margin: 0 0 15px 0
    padding: 0
    +tablet-large
      display: none

  &__title-desktop
    display: none
    +tablet-large
      display: block

  &__gallery
    +tablet-large
      +span(9)
    +desktop
      +span(9)

  &__informations
    font-size: 13px
    line-height: 1.4em
    +tablet-large
      +span(3)
      +last
    +desktop
      +span(3)
      +last

    p
      margin-top: 0

    h1, h2
      font-size: 13px
      font-weight: bold
      text-transform: uppercase
      margin: 0
      padding: 0

  &__block
    padding-bottom: 1.4em

@import "sprites__data"

@mixin sprite-position2($sprite, $offset-x: 0px, $offset-y: 0px)
  $x: map-get($sprite, 'offset_x') + $offset-x
  $y: map-get($sprite, 'offset_y') + $offset-y
  background-position: $x $y

// override sprite-image for correct path..
@mixin sprite-image($sprite)
  $url: image-url(map-get($sprite, 'image'))
  background-image: $url

@mixin sprite-image-retina($sprite)
  $url: map-get($sprite, 'image')
  $url: str-replace($url, '-.png', '-@2x.png')
  $url: image-url($url)
  background-image: $url !important

@mixin sprite-ui($sprite, $offset-x: 0px, $offset-y: 0px, $set-size: false)
  +sprite-image($sprite)
  +sprite-position2($sprite, $offset-x, $offset-y)
  background-repeat: no-repeat

  @if $set-size == true
    +sprite-width($sprite)
    +sprite-height($sprite)

  @media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 2)
    .backgroundsize &
      +sprite-image-retina($sprite)
      background-size: map-get($sprite, 'total_width') auto !important

// Susy Configuration
$susy: (
  columns: 1,
  gutters: (30/67),
  math: fluid,
  output: float,
  global-box-sizing: border-box
);

$mobile-large_layout: (
  columns: 6,
  column-width: 66,
  gutters: (10/66),
  math: fluid,
  output: float,
);

$tablet-small_layout: (
  columns: 9,
  column-width: 66,
  gutters: (10/66),
  math: fluid,
  output: float,
);

$tablet-large_layout: (
  columns: 12,
  column-width: 66,
  gutters: (10/66),
  math: fluid,
  output: float,
);

$desktop_layout: (
  columns: 13,
  column-width: 66,
  gutters: (10/66),
  math: fluid,
  output: float,
);

$desktop-hd_layout: (
  columns: 13,
  column-width: 80,
  gutters: (14/80),
  math: fluid,
  output: float,
);

$mobile-large: 446px;
$tablet-small: 674px;
$tablet-large: 902px;
$desktop: 978px;
$desktop-hd: 1182px;
$grid_padding: 10px;

$breakpoint-menu: 800px;

// Reference breakpoints
$breakpoint-iphone-portrait: 320px;
$breakpoint-iphone-landscape: 480px;
$breakpoint-ipad-portrait: 768px;
$breakpoint-ipad-landscape: 1024px;

@mixin mobile-large{
  @include susy-breakpoint(($mobile-large + $grid_padding * 2), $mobile-large_layout) {
    @content;
  }
}

@mixin tablet-small{
  @include susy-breakpoint(($tablet-small + $grid_padding * 2), $tablet-small_layout) {
    @content;
  }
}

@mixin tablet-large{
  @include susy-breakpoint(($tablet-large + $grid_padding * 2), $tablet-large_layout) {
    @content;
  }
}

@mixin desktop{
  @include susy-breakpoint(($desktop + $grid_padding * 2), $desktop_layout) {
    @content;
  }
}

@mixin desktop-hd{
  @include susy-breakpoint(($desktop-hd + $grid_padding * 2), $desktop-hd_layout) {
    @content;
  }
}

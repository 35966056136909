.site-footer
  font-size: 11px
  line-height: 1.3em
  padding: 20px 0
  text-transform: uppercase

  .vcard
    &:before
      content: ""
      display: block
      width: 30px
      height: 1px
      background: #000
      margin-bottom: 10px

// Custom colors
$primary: #0af
$black: #000
$background: #fff
$green: #9ec61c
$grey: #e5e5e5
$white: #fff

$error: #bb5f67

// Blueprint colors
$font-color: $black
$header-color: $font-color - #111

$link-color: $primary
$link-hover-color: $black
$link-focus-color: $link-hover-color
$link-active-color: $link-color
$link-visited-color: $link-color

.post
  +desktop
    +span(4)
    +suffix(1)

  &__image
    margin-bottom: 15px
    img
      width: 100%
      height: auto

  &__title
    font-size: 15px
    margin: 0 0 1em 0
    padding: 0

  &__content
    font-size: 13px
    line-height: 1.45em
    margin-bottom: 15px
    padding-bottom: 15px
    border-bottom: 3px solid $grey

    p
      margin: 0 0 1.45em 0

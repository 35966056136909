.collaborateur
  margin-bottom: 10px

  &__title
    margin: 0
    font-size: 15px
    text-transform: uppercase

  &__content
    font-size: 14px

  a
    text-decoration: none
    color: $black !important

  p
    margin: 0

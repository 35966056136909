// custom fonts
@font-face
  font-family: 'basel'
  font-weight: 400
  src: url('../dist/assets/fonts/basel-regular.woff2') format('woff2'), url('../dist/assets/fonts/basel-regular.woff') format('woff')

@font-face
  font-family: 'basel'
  font-weight: 700
  src: url('../dist/assets/fonts/basel-bold.woff2') format('woff2'), url('../dist/assets/fonts/basel-bold.woff') format('woff')

// Font
$base-font-size: 12px
$base-line-height: 24px
$em-base: $base-font-size // for bourbon px-to-em function.
$base-font-family: "basel", "Helvetica Neue", Helvetica, sans-serif

// Disable bourbon prefixing, handled by autoprefixer.
$prefix-for-webkit: false
$prefix-for-mozilla: false
$prefix-for-microsoft: false
$prefix-for-opera: false
$prefix-for-spec: false

html
  figure
    margin: 0 !important

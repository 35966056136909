// Enable global box-sizing(border-box)
@include global-box-sizing(border-box)

.container
  +container
  margin: 0 $grid_padding

  +mobile-large
    +container($mobile-large)

  +tablet-small
    +container($tablet-small)

  +tablet-large
    +container($tablet-large)

  +desktop
    +container($desktop)

  +desktop-hd
    +container($desktop-hd)

.bx-wrapper
  position: relative
  margin: 0
  padding: 0
  *zoom: 1

  img
    max-width: 100%
    display: block

.bx-viewport
  /*fix other elements on the page moving (on Chrome)*/
  transform: translateZ(0)

.bxslider
  +reset-box-model
  +no-bullets

.bx-controls
  position: relative
  height: 48px
  max-width: 814px
  margin-bottom: 15px
  +desktop
    position: absolute
    height: 100%
    top: 0
    left: 0
    right: 0
    bottom: 0
    margin: 0

.bx-wrapper:hover
  .bx-prev,
  .bx-next
    opacity: 1
    transition: opacity 0.2s

.bx-prev,
.bx-next
  position: absolute
  display: block
  height: 48px
  opacity: 1
  top: 0
  width: 50%
  background: rgba(255, 255, 255, 0.3)
  z-index: 1000
  transition: opacity 0.4s
  line-height: 100em
  overflow: hidden
  +desktop
    width: 66px
    height: inherit
    opacity: 0
    top: 0
    bottom: 0
  &:before
    content: ""
    display: block
    width: 21px
    height: 17px
    position: absolute
    top: 14px
    +desktop
      top: 212px
    +desktop-hd
      top: 242px

.bx-prev
  left: 0
  &:before
    +sprite-ui($prev)
    left: 40%
    +desktop
      left: 21px

.bx-next
  right: 0
  &:before
    +sprite-ui($next)
    right: 40%
    +desktop
      right: 21px
